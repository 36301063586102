import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, Search, Send, PlusCircle, Clock, Mic } from 'lucide-react';
import axios from 'axios';

const companions = [
  {
    id: 1,
    name: 'Ms. Molly',
    description: 'Chat with Molly about anything under the sun. Whether you\'re sharing a funny story or pondering life\'s big questions.',
    image: '/companions/molly.avif',
    prompt: "You are Ms. Molly, a friendly chat companion. Engage in casual conversation, ask follow-up questions, and respond with warmth and empathy. Keep your responses concise and natural, as if chatting with a friend.",
    tags: ['friendly', 'life advice', 'humor']
  },
  {
    id: 2,
    name: 'History Teacher Harold',
    description: 'Relive and learn from the past as you discuss historical events and their impact on today\'s world.',
    image: '/companions/harold.avif',
    prompt: "You are History Teacher Harold. Discuss historical events and their context, relating them to current events when relevant. Use analogies to make complex historical concepts more accessible.",
    tags: ['history', 'education', 'world events']
  },
  {
    id: 3,
    name: 'Linda the Librarian',
    description: 'Discover new books and revisit classic literature with personalized recommendations and engaging discussions.',
    image: '/companions/linda.avif',
    prompt: "You are Linda the Librarian. Offer book recommendations based on user preferences, discuss literary themes, and engage in thoughtful conversations about both classic and contemporary works.",
    tags: ['books', 'literature', 'recommendations']
  },
  {
    id: 4,
    name: 'Glenda the Gardener',
    description: 'Learn tips and tricks to nurture your garden and bring more beauty into your outdoor spaces.',
    image: '/companions/glenda.avif',
    prompt: "You are Glenda the Gardener. Share gardening tips, plant care advice, and ideas for creating beautiful outdoor spaces. Be encouraging and patient, especially with novice gardeners.",
    tags: ['gardening', 'plants', 'outdoor']
  },
  {
    id: 5,
    name: 'Sports Fan Frank',
    description: 'Stay up-to-date with your favorite sports teams and relive exciting moments in sports history.',
    image: '/companions/frank.avif',
    prompt: "You are Sports Fan Frank. Discuss current sports events, share historical sports trivia, and engage in friendly debates about teams and players across various sports.",
    tags: ['sports', 'athletics', 'team spirit']
  },
  {
    id: 6,
    name: 'Counselor Carol',
    description: 'Gain emotional support and practical advice for life\'s challenges in a safe, judgment-free conversation.',
    image: '/companions/carol.avif',
    prompt: "You are Counselor Carol. Offer emotional support, practical advice, and coping strategies for life's challenges. Always maintain a non-judgmental and supportive tone.",
    tags: ['counseling', 'emotional support', 'life advice']
  },
];

const Sidebar = ({ onNewChat, onViewHistory }) => (
  <div className="w-64 bg-gray-800 text-white p-4">
    <button onClick={onNewChat} className="w-full bg-gray-700 text-white rounded-lg p-2 mb-4 flex items-center">
      <PlusCircle size={20} className="mr-2" />
      New chat
    </button>
    <button onClick={onViewHistory} className="w-full text-left p-2 hover:bg-gray-700 rounded-lg mb-2 flex items-center">
      <Clock size={20} className="mr-2" />
      History
    </button>
    <button className="w-full text-left p-2 hover:bg-gray-700 rounded-lg mb-2 flex items-center opacity-50 cursor-not-allowed">
      <Mic size={20} className="mr-2" />
      Voice (Coming soon)
    </button>
  </div>
);

const CompanionCard = ({ companion, onStartChat }) => (
    <div className="flex flex-col bg-white rounded-3xl p-8 w-[356px] min-h-[500px] gap-6">
      <img src={companion.image} alt={companion.name} className="w-full h-64 object-cover rounded-2xl" />
      <h3 className="text-2xl font-semibold">{companion.name}</h3>
      <p className="text-lg text-gray-600 flex-grow">{companion.description}</p>
      <button
        onClick={() => onStartChat(companion)}
        className="bg-[#1E3932] text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-opacity-90 transition-colors duration-200"
      >
        Start Chatting
      </button>
    </div>
  );
  
  const ChatApp = () => {
    const [activeScreen, setActiveScreen] = useState('home');
    const [currentChat, setCurrentChat] = useState(null);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [streamedResponse, setStreamedResponse] = useState('');
    const [showHistory, setShowHistory] = useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const messagesEndRef = useRef(null);
  
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
  
    useEffect(scrollToBottom, [currentChat, streamedResponse]);
  
    const startNewChat = (companion) => {
      const newChat = {
        companion,
        messages: [{ content: `Hello! I'm ${companion.name}. How can I help you today?`, sender: 'ai' }]
      };
      setCurrentChat(newChat);
      setActiveScreen('chat');
      setShowHistory(false);
    };
  
    const sendMessage = async () => {
      if (!message.trim() || !currentChat) return;
  
      const updatedChat = { ...currentChat };
      updatedChat.messages.push({ content: message, sender: 'user' });
      setCurrentChat(updatedChat);
      setMessage('');
      setIsLoading(true);
  
      try {
        const response = await axios.post('https://dearfriend-demo.brayden-b8b.workers.dev/api/chat', {
          message,
          companionName: currentChat.companion.name,
          companionPrompt: currentChat.companion.prompt
        }, {
            headers: {
              'Authorization': `Bearer ffa69ab133ec4e928df9cc4a441b3f2b`
            }
          });
  
        // Simulated streaming
        const aiResponse = response.data.reply;
        setStreamedResponse('');
        for (let i = 0; i < aiResponse.length; i++) {
          setStreamedResponse(prev => prev + aiResponse[i]);
          await new Promise(resolve => setTimeout(resolve, 20));
        }
  
        updatedChat.messages.push({ content: aiResponse, sender: 'ai' });
        setCurrentChat(updatedChat);
      } catch (error) {
        console.error('Error sending message:', error);
      } finally {
        setIsLoading(false);
        setStreamedResponse('');
      }
    };
  
    const fetchHistory = async () => {
      try {
        const response = await axios.get('https://dearfriend-demo.brayden-b8b.workers.dev/api/history', {
          headers: {
            'Authorization': `Bearer ffa69ab133ec4e928df9cc4a441b3f2b`
          }
        });
        setConversationHistory(response.data);
      } catch (error) {
        console.error('Error fetching history:', error);
      }
    };
  
    const filteredCompanions = companions.filter(companion =>
      companion.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      companion.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      companion.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  
    const renderChatScreen = () => (
      <div className="flex h-full bg-[#F9F7F2]">
        <div className="w-1/3 flex flex-col bg-white shadow-lg">
          <img src={currentChat.companion.image} alt={currentChat.companion.name} className="w-full p-4 rounded-2xl h-64 object-cover" />
          <div className="p-6">
            <h2 className="text-3xl font-bold mb-2">{currentChat.companion.name}</h2>
            <p className="text-xl text-gray-600 mb-4">{currentChat.companion.description}</p>
            <div className="space-y-2">
              <button onClick={() => setActiveScreen('home')} className="w-full bg-gray-200 text-gray-800 rounded-lg p-2 flex items-center justify-center">
                <PlusCircle size={20} className="mr-2" />
                New chat
              </button>
              <button onClick={() => { fetchHistory(); setShowHistory(true); }} className="w-full bg-gray-200 text-gray-800 rounded-lg p-2 flex items-center justify-center">
                <Clock size={20} className="mr-2" />
                History
              </button>
              <button className="w-full bg-gray-200 text-gray-800 rounded-lg p-2 flex items-center justify-center opacity-50 cursor-not-allowed">
                <Mic size={20} className="mr-2" />
                Voice (Coming soon)
              </button>
            </div>
          </div>
        </div>
        <div className="w-2/3 flex flex-col">
          <header className="bg-white p-4 shadow-md flex items-center">
            <button onClick={() => setActiveScreen('home')} className="mr-4">
              <ChevronLeft size={32} />
            </button>
            <h2 className="font-semibold text-2xl">Chat with {currentChat.companion.name}</h2>
          </header>
          <div className="flex-1 overflow-y-auto p-6 space-y-6">
            {currentChat.messages.map((msg, index) => (
              <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                {msg.sender === 'ai' && (
                  <img src={currentChat.companion.image} alt="" className="w-12 h-12 rounded-full mr-4" />
                )}
                <div className={`max-w-xs lg:max-w-md xl:max-w-lg px-6 py-3 rounded-2xl ${
                  msg.sender === 'user' ? 'bg-[#1E3932] text-white' : 'bg-white border border-gray-300'
                }`}>
                  <p className="text-xl">{msg.content}</p>
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start">
                <img src={currentChat.companion.image} alt="" className="w-12 h-12 rounded-full mr-4" />
                <div className="bg-white border border-gray-300 rounded-2xl px-6 py-3">
                  <p className="text-xl">{streamedResponse || 'Thinking...'}</p>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="bg-white p-6 shadow-md">
            <div className="flex items-center bg-gray-100 rounded-full p-2">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
                placeholder="Type a message..."
                className="flex-1 bg-transparent outline-none px-4 py-2 text-xl"
              />
              <button
                onClick={sendMessage}
                className="bg-[#1E3932] text-white p-3 rounded-full hover:bg-opacity-90 transition-colors duration-200"
              >
                <Send size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  
    const renderHomeScreen = () => (
      <div className="flex flex-col h-full bg-[#F9F7F2]">
        <header className="p-8">
          <h1 className="text-5xl font-bold mb-4">Hello, Friend!</h1>
          <p className="text-2xl text-gray-600">{new Date().toLocaleDateString('en-US', { weekday: 'long', month: 'long', day: 'numeric' })}</p>
        </header>
        <div className="px-8 mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search companions or tags"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-4 pl-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1E3932] text-xl"
            />
            <Search className="absolute left-4 top-4 text-gray-400" size={28} />
          </div>
        </div>
        <div className="flex-1 overflow-y-auto px-8 pb-8">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredCompanions.map((companion) => (
              <CompanionCard key={companion.id} companion={companion} onStartChat={startNewChat} />
            ))}
          </div>
        </div>
      </div>
    );
  
    const renderHistoryScreen = () => (
      <div className="flex flex-col h-full bg-[#F9F7F2]">
        <header className="p-8 flex items-center">
          <button onClick={() => setShowHistory(false)} className="mr-4">
            <ChevronLeft size={32} />
          </button>
          <h2 className="text-3xl font-bold">Conversation History</h2>
        </header>
        <div className="flex-1 p-6 overflow-y-auto">
          {conversationHistory.map((conv, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-4 mb-4">
              <p className="font-semibold">{conv.companion_name}</p>
              <p>User: {conv.user_message}</p>
              <p>AI: {conv.ai_reply}</p>
              <p className="text-sm text-gray-500">{new Date(conv.created_at).toLocaleString()}</p>
            </div>
          ))}
        </div>
      </div>
    );
  
    return (
      <div className="h-screen max-w-7xl mx-auto bg-[#F9F7F2] font-sans antialiased text-gray-900 flex">
        {showHistory ? renderHistoryScreen() : (activeScreen === 'home' ? renderHomeScreen() : renderChatScreen())}
      </div>
    );
  };
  
  export default ChatApp;
